import * as React from "react";
import DevelopersSection from "../components/DevelopersSection";
import FeaturesSection from "../components/FeaturesSection";
import FooterSection from "../components/FooterSection";
import InvestorsSection from "../components/InvestorsSection";
import LandingSection from "../components/LandingSection";
import MilkHeader from "../components/MilkHeader";
import PageMeta from "../components/PageMeta";
import RoadmapSection from "../components/RoadmapSection";
import SubscribeSection from "../components/SubscribeSection";
import UsersSection from "../components/UsersSection";
import NewsSection from "../components/NewsSection";
import { I18nextContext, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { Tab as HUITab } from "@headlessui/react";
import classNames from "classnames";
import WhyBuilders from "../components/WhyBuilders";
import SecuritySection from "../components/SecuritySection";
import UsersWhatIsIt from "../components/UsersWhatIsIt";
import HowToConnect from "../components/HowToConnect";
import { MDXProvider } from "@mdx-js/react";
import components from "../components/MdxComponents";

const Tab = ({ children }: { children: React.ReactNode }) => (
  <HUITab
    className={({ selected }) =>
      classNames(
        "w-full rounded-[40px] p-10 text-sm font-medium leading-5 ",
        selected ? "bg-orange text-white" : "text-disabled hover:text-white"
      )
    }
  >
    {children}
  </HUITab>
);

const TabPanel = ({ children }: { children: React.ReactNode }) => (
  <HUITab.Panel className={classNames("rounded-xl p-3", "focus:outline-none")}>
    {children}
  </HUITab.Panel>
);

const IndexPage = () => {
  const { t } = useTranslation();
  const langContext = React.useContext(I18nextContext);
  return (
    <MDXProvider components={components}>
      <main>
        <PageMeta
          title={t("Milkomeda • Cross-blockchain DApp creation made simple")}
          description={t(
            "Milkomeda brings the most used smart-contract programming language across blockchains. Develop once, deploy everywhere."
          )}
          locale={t("en_US")}
          language={langContext.language}
        />
        <MilkHeader />
        <div>
          <LandingSection />
          <NewsSection />
          <section id="select-what-define" className="relative">
            <div className="section-custom-top">
              <div>
                <h2 className="container heading-2">
                  <Trans>Select what defines you best</Trans>
                </h2>
                <HUITab.Group>
                  <HUITab.List className="container max-w-[288px] md:max-w-[516px] mx-auto flex space-x-1 bg-blue-bg-dark p-8 rounded-[160px] h-[64px] shadow-tabs mb-40 md:mb-64">
                    <Tab>
                      <Trans>Users</Trans>
                    </Tab>
                    <Tab>
                      <Trans>Builders</Trans>
                    </Tab>
                  </HUITab.List>
                  <HUITab.Panels className="mt-2">
                    {/* Panel: Users */}
                    <TabPanel>
                      <UsersWhatIsIt />
                      <UsersSection />
                      <HowToConnect />
                      <SecuritySection />
                    </TabPanel>
                    {/* Panel: Builders */}
                    <TabPanel>
                      <WhyBuilders />
                      <DevelopersSection />
                    </TabPanel>
                  </HUITab.Panels>
                </HUITab.Group>

                {/* TODO */}
              </div>
            </div>
          </section>
          {/* <FeaturesSection /> */}
          {/*<RoadmapSection />*/}
          {/*<InvestorsSection />*/}
          <SubscribeSection />
        </div>
      </main>
      <FooterSection footerImage={"/images/footer-bg@2x.webp 2x, /images/footer-bg.webp 1x"} />
    </MDXProvider>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
