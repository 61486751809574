import * as React from "react";
import { Trans } from "react-i18next";
import LinkIcon from "../../static/images/icons/link-icon.inline.svg";

const SecuritySection = () => {
  return (
    <section id="security" className="relative">
      <div className="section-custom-top">
        <div className="container text-center relative z-20 pb-64">
          <h2 className="heading-2 mb-24">
            <Trans>Security</Trans>
          </h2>
          <p className="mb-48 md:mb-64 px-30">
            <Trans>
              Milkomeda’s team treats network security with the utmost importance, making every
              effort to ensure that the key elements of the system are properly audited. Our team
              works with trusted and reputable blockchain auditing companies to report on the audit
              of Milkomeda’s smart contracts.
            </Trans>
          </p>
          <div className="flex flex-col lg:flex-row items-center justify-center space-y-24 lg:space-y-0 lg:space-x-32">
            <a
              className="flex items-center justify-center btn-secondary"
              href="/docs/Milkomeda-audit-by-ArbitraryExecution.pdf"
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <Trans>Arbitrary Execution's Audit</Trans>
              <LinkIcon className="ml-8" />
            </a>
            <a
              className="flex items-center justify-center btn-secondary"
              href="/docs/Milkomeda-audit-by-Certik.pdf"
              target="_blank"
              download
            >
              <Trans>Certik's Audit</Trans>
              <LinkIcon className="ml-8" />
            </a>
            <a
              className="flex items-center justify-center btn-secondary"
              href="/docs/formal-Verification-Report-of-dcSpark-SidechainBridge.pdf"
              target="_blank"
              download
            >
              <Trans>Certora's Audit</Trans>
              <LinkIcon className="ml-8" />
            </a>
          </div>
        </div>
      </div>

      <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
        <div className="layout-grid z-10">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </section>
  );
};

export default SecuritySection;
