import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

const UsersSection = () => {
  return (
    <section id="for-users" className="relative">
      <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
        <div className="layout-grid z-10">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="container relative z-10 pt-64">
        {/* <h2 className="heading-2 mb-16">
          <Trans>FOR USERS</Trans>
        </h2>
        <div className="flex flex-col md:flex-row justify-center items-center text-menu-white text-center relative z-10">
          <Trans>How to connect?</Trans>
          <div className="text-center mx-5">
            <a href="/#how-to-connect" className="link">
              <Trans>Follow our guide here</Trans>
            </a>
          </div>
        </div> */}
        <ul className=" -mx-10 flex flex-wrap justify-center">
          <li className="card-item card card-b">
            <div className="box-item">
              <div className="pt-50 px-20">
                <p className="text-40 font-semibold">
                  <Trans>DeFi</Trans>
                </p>
                <p className="mt-10 text-menu-white leading-normal">
                  <Trans>Stablecoins, DEXes, Money Markets, DeFi 2.0, etc.</Trans>
                </p>
              </div>
              <div className="box-item__image">
                <img
                  className="w-full"
                  srcSet="/images/defi@2x.webp 2x, /images/defi.webp 1x"
                  src="/images/defi.webp"
                  width="306"
                  height="290"
                  alt="defi"
                />
              </div>
            </div>
          </li>
          <li className="card-item card card-b animation-delay-300">
            <div className="box-item">
              <div className="pt-50 px-20">
                <p className="text-40 font-semibold">
                  <Trans>Bridges</Trans>
                </p>
                <p className="mt-10 text-menu-white leading-normal">
                  <Trans>Connecting Algorand, Solana and Cardano.</Trans>
                </p>
              </div>
              <div className="box-item__image">
                <img
                  className="w-full"
                  srcSet="/images/bridges@2x.webp 2x, /images/bridges.webp 1x"
                  src="/images/bridges.webp"
                  width="306"
                  height="290"
                  alt="bridges"
                />
              </div>
            </div>
          </li>
          <li className="card-item card card-b animation-delay-600">
            <div className="box-item">
              <div className="pt-50 px-20">
                <p className="text-40 font-semibold">
                  <Trans>Games</Trans>
                </p>
                <p className="mt-10 text-menu-white leading-normal">
                  <Trans>Play to Earn and other Blockchain based games.</Trans>
                </p>
              </div>
              <div className="box-item__image">
                <img
                  className="w-full"
                  srcSet="/images/games@2x.webp 2x, /images/games.webp 1x"
                  src="/images/games.webp"
                  width="306"
                  height="290"
                  alt="games"
                />
              </div>
            </div>
          </li>
          <li className="card-item card card-b animation-delay-900">
            <div className="box-item">
              <div className="pt-50 px-20">
                <p className="text-40 font-semibold">
                  <Trans>NFTs</Trans>
                </p>
                <p className="mt-10 text-menu-white leading-normal">
                  <Trans>NFT platform support, unlocking the power of NFTs</Trans>
                </p>
              </div>
              <div className="box-item__image">
                <img
                  className="w-full"
                  srcSet="/images/nfts@2x.webp 2x, /images/nfts.webp 1x"
                  src="/images/nfts.webp"
                  width="306"
                  height="290"
                  alt="nfts"
                />
              </div>
            </div>
          </li>
        </ul>
        <div className="space-custom"></div>
      </div>
      <div className="space-custom"></div>
    </section>
  );
};

export default UsersSection;
