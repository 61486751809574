import * as React from "react";
import { Trans } from "react-i18next";

const WhyBuilders = () => {
  return (
    <section id="why" className="relative">
      <div className="section-custom-top">
        <div className="container ">
          <ul className="">
            <li className="why-item">
              <div className="why-item__text z-10">
                <p className="why-item__text__title">
                  <Trans>Laying down the road for an interoperable future</Trans>
                </p>
                <ul className="text-menu-white">
                  <li className="flex mb-25">
                    <span className="check">
                      <img className="image-sd" src="/images/icons/check.svg" width="16" height="16" alt="check" />
                    </span>
                    <Trans>
                      Implementing the core foundation for non-EVM ecosystems to experience the benefits of both rollups
                      and Solidity.
                    </Trans>
                  </li>
                  <li className="flex mb-25">
                    <span className="check">
                      <img className="image-sd" src="/images/icons/check.svg" width="16" height="16" alt="check" />
                    </span>
                    <Trans>
                      Broader selection of dApps by allowing devs to port over existing projects from Ethereum.
                    </Trans>
                  </li>
                  <li className="flex">
                    <span className="check">
                      <img className="image-sd" src="/images/icons/check.svg" width="16" height="16" alt="check" />
                    </span>
                    <Trans>
                      Enables the latest innovations in ZK tech to be implemented as L3+ solutions on top of Milkomeda.
                    </Trans>
                  </li>
                </ul>
              </div>
              <div className="why-item__image z-10">
                <img
                  className="w-full h-full object-cover"
                  src="/images/why1.webp"
                  alt="astrounaut"
                  srcSet="/images/why1@2x.webp 2x, /images/why1.webp 1x"
                  width="636"
                  height="360"
                />
              </div>
            </li>
            <li className="why-item why-item-reverse">
              <div className="why-item__text z-10">
                <p className="why-item__text__title">
                  <Trans>Built by developers, for developers</Trans>
                </p>
                <ul className="text-menu-white">
                  <li className="flex mb-25">
                    <span className="check">
                      <img className="image-sd" src="/images/icons/check.svg" width="16" height="16" alt="check" />
                    </span>
                    <Trans>Deploy a Solidity (EVM) based smart contract in minutes.</Trans>
                  </li>
                  <li className="flex mb-25">
                    <span className="check">
                      <img className="image-sd" src="/images/icons/check.svg" width="16" height="16" alt="check" />
                    </span>
                    <Trans>All the tooling of Ethereum works out of the box.</Trans>
                  </li>
                  <li className="flex">
                    <span className="check">
                      <img className="image-sd" src="/images/icons/check.svg" width="16" height="16" alt="check" />
                    </span>
                    <Trans>Guides and examples are available to get you started building on Milkomeda.</Trans>
                  </li>
                </ul>
              </div>
              <div className="why-item__image z-10">
                <img
                  className="w-full h-full object-cover"
                  src="/images/why2.webp"
                  alt="touch"
                  srcSet="/images/why2@2x.webp 2x, /images/why2.webp 1x"
                  width="636"
                  height="360"
                />
              </div>
            </li>
            <li className="why-item">
              <div className="why-item__text z-10">
                <p className="why-item__text__title">
                  <Trans>Larger Talent Pool and Faster MVPs</Trans>
                </p>
                <ul className="text-menu-white">
                  <li className="flex mb-25">
                    <span className="check">
                      <img className="image-sd" src="/images/icons/check.svg" width="16" height="16" alt="check" />
                    </span>
                    <Trans>
                      Tap into the talent and resources of the largest community for smart contracts (Solidity) while
                      building on chains like Cardano, Algorand and Solana.
                    </Trans>
                  </li>
                  <li className="flex">
                    <span className="check">
                      <img className="image-sd" src="/images/icons/check.svg" width="16" height="16" alt="check" />
                    </span>
                    <Trans>
                      Move faster and cheaper while still accessing tooling and expertise from core development to
                      security auditing.
                    </Trans>
                  </li>
                </ul>
              </div>
              <div className="why-item__image z-10">
                <img
                  className="w-full h-full object-cover"
                  src="/images/why3.webp"
                  alt="starship"
                  srcSet="/images/why3@2x.webp 2x, /images/why3.webp 1x"
                  width="636"
                  height="360"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
        <div className="layout-grid z-10">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </section>
  );
};

export default WhyBuilders;
