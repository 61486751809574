import * as React from "react";
import InfoIcon from "../../../static/images/icons/info-icon.inline.svg";
import DangerIcon from "../../../static/images/icons/danger-icon.inline.svg";
import classNames from "classnames";

const alertVariantsMap = {
  info: InfoIcon,
  danger: DangerIcon,
};
const Alert = ({
  variant,
  title,
  children,
}: {
  variant?: "danger" | "info";
  title?: string;
  children: React.ReactNode;
}) => {
  const Icon = variant ? alertVariantsMap[variant] : InfoIcon;
  return (
    <div
      className={classNames(
        "flex rounded-[4px] my-20 p-16 items-start bg-[#191919] border-l-2",
        variant === "danger" ? "border-l-[#E53E3E]" : "border-l-white",
        title ? "pb-10" : "pb-0"
      )}
    >
      <Icon className={variant === "danger" ? "text-[#E53E3E]" : "text-white"} />
      <div className="pl-14 flex-col basis-full text-16 pr-16">
        {title ? <h4 className="font-bold mb-8">{title}</h4> : null}
        <div className="text-menu-white">{children}</div>
      </div>
    </div>
  );
};

const components = {
  h1: (props: Object) => <h1 className="font-bold text-40 text-white mb-24" {...props} />,
  p: (props: Object) => <p className="text-menu-white text-16 mb-18" {...props} />,
  a: (props: Object) => <a className="underline text-white text-16" {...props} />,
  blockquote: (props: Object) => (
    <blockquote className="border-l-4 border-l-gray-300 pl-16 text-16 text-menu-white" {...props} />
  ),
  ul: (props: Object) => (
    <ul className="list-disc mb-16 pl-16 text-16 text-menu-white" {...props} />
  ),
  table: (props: Object) => <table className="mb-30" {...props} />,
  th: (props: Object) => <th className="p-5 border-[1px] border-gray-100" {...props} />,
  td: (props: Object) => <td className="p-5 border-[1px] border-gray-100" {...props} />,

  /*
    Note: we are currently handle images by using `gatsby-remark-images` for responsive/optimized images
    but this only works for a specific custom width, so we are importing from `images` directory in case
    we want our image to have full width
  */
  // Custom components
  Alert,
};
export default components;
