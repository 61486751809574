import * as React from "react";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper";
import { graphql, useStaticQuery } from "gatsby";
import { formatDateLocaleString } from "../utils/date";
import { Locales } from "../../locales/languages";

const NewsCard = ({
  thumbnail,
  title,
  pubDate,
}: {
  thumbnail: string;
  title: string;
  pubDate: string;
}) => {
  return (
    <div
      style={{ backgroundImage: `url(${thumbnail})` }}
      className="flex relative aspect-square overflow-hidden rounded-2xl bg-cover bg-no-repeat bg-center shadow-news transition hover:shadow-2xl"
    >
      <div className="fade absolute inset-0 z-0" />
      <div className="relative self-end z-10 p-16">
        <div className="absolute inset-0 blur-sm bg-blue-bg-dark/60" />
        <h3 className="relative z-10 font-bold text-20 text-white line-clamp-2 leading-7 ">
          <Trans>{title}</Trans>
        </h3>
        <p className="relative z-10 text-disabled text-16 font-medium mt-8">
          {formatDateLocaleString(pubDate)}
        </p>
      </div>
    </div>
  );
};

const NewsSection = () => {
  const { language } = useI18next();

  const data = useStaticQuery<{
    milkomedafoundationMedium: {
      items: {
        guid: string;
        categories: string[];
        link: string;
        thumbnail: string;
        title: string;
        pubDate: string;
      }[];
    };
    milkomedaJaMedium: {
      items: {
        guid: string;
        categories: string[];
        link: string;
        thumbnail: string;
        title: string;
        pubDate: string;
      }[];
    };
  }>(graphql`
    query {
      milkomedafoundationMedium {
        items {
          guid
          link
          pubDate(formatString: "")
          categories
          title
          thumbnail
        }
      }
      milkomedaJaMedium {
        items {
          guid
          link
          pubDate(formatString: "")
          categories
          title
          thumbnail
        }
      }
    }
  `);
  const { items: ENpublications } = data.milkomedafoundationMedium;
  const { items: JApublications } = data.milkomedaJaMedium;

  const publications = language === Locales.english ? ENpublications : JApublications;

  return (
    <section id="news" className="relative">
      <div className="section-custom-top bg-blue-bg-dark pt-10 pb-20 md:pb-0">
        <div className="container relative">
          <div>
            <h2 className="heading-2 mb-30">
              <Trans>News</Trans>
            </h2>
            <div className="w-full relative px-16">
              <Swiper
                spaceBetween={10}
                navigation={{
                  nextEl: ".swiper-next-news",
                  prevEl: ".swiper-prev-news",
                }}
                modules={[Navigation, A11y]}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 16,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 18,
                  },
                  992: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                  },
                }}
              >
                {publications.map((news, index) => (
                  <SwiperSlide className="py-20 px-6" key={index}>
                    <a href={news.link} target="_blank" rel="noopener noreferrer">
                      <NewsCard {...news} />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-prev-news transform rotate-180">
                <p className="sr-only">Go Prev</p>
                <img src="/images/icons/arrow_right.svg" alt="" />
              </div>
              <div className="swiper-next-news">
                <p className="sr-only">Go Next</p>
                <img src="/images/icons/arrow_right.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
        <div className="layout-grid z-10">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="w-full">
        <img
          className="w-full"
          srcSet="/images/oval_BG_down@2x.webp 2x, /images/oval_BG_down.webp 1x"
          src="/images/oval_BG_down.webp"
          width="1600"
          height="235"
          alt="oval-top"
        />
      </div>
    </section>
  );
};

export default NewsSection;
