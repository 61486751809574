import * as React from "react";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import { externalUrlsByLocale } from "../utils/constants";
import { Locales } from "../../locales/languages";

const DevelopersSection = () => {
  const { language } = useI18next();

  return (
    <section id="for-developers" className="relative">
      <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
        <div className="layout-grid z-10">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="section-custom-top my-64">
        <div className="container relative">
          <h2 className="heading-2">
            <Trans>FOR DEVELOPERS</Trans>
          </h2>
          <ul className="feature-list">
            <li className="feature-item">
              <div className="w-64 h-64">
                <img src="/images/icons/racket.svg" width="64" height="64" alt="racket" />
              </div>
              <p className="text-24 mt-32 font-semibold">
                <Trans>Build once, deploy everywhere</Trans>
              </p>
              <p className="mt-20 text-menu-white leading-normal">
                <Trans>
                  Thanks to Milkomeda, you can deploy your project - without changes - in most of
                  the promising L1 blockchains.
                </Trans>
              </p>
            </li>
            <li className="feature-item">
              <div className="w-64 h-64">
                <img src="/images/icons/evm.svg" width="64" height="64" alt="evm" />
              </div>
              <p className="text-24 mt-32 font-semibold">
                <Trans>Use all the tooling from EVM.</Trans>
              </p>
              <p className="mt-20 text-menu-white leading-normal">
                <Trans>
                  Building, deploying, debugging, and auditing all work the same way as in Ethereum.
                </Trans>
              </p>
            </li>
            <li className="feature-item">
              <div className="w-64 h-64">
                <img
                  src="/images/icons/security-consistency.svg"
                  width="64"
                  height="64"
                  alt="security-consistency"
                />
              </div>
              <p className="text-24 mt-32 font-semibold">
                <Trans>Security consistency among deployments</Trans>
              </p>
              <p className="mt-20 text-menu-white leading-normal">
                <Trans>
                  Your multi-chain deployed code has the same security properties. No need to do
                  individual audits per chain.
                </Trans>
              </p>
            </li>
            <li className="feature-item">
              <div className="w-64 h-64">
                <img
                  src="/images/icons/start-building-right-now.svg"
                  width="64"
                  height="64"
                  alt="start-building-right-now"
                />
              </div>
              <p className="text-24 mt-32 font-semibold">
                <Trans>Start building right now</Trans>
              </p>
              <p className="mt-20 text-menu-white leading-normal">
                <Trans>
                  Milkomeda has its sidechain Cardano devnet and mainnet already working.
                </Trans>
                <br />
                <br />
                <a
                  href={externalUrlsByLocale.milkomedaDoc[language as Locales]}
                  target="_blank"
                  rel="noopener"
                  className="link"
                >
                  <Trans>Follow our guide here</Trans>
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default DevelopersSection;
