import * as React from "react";
import { Trans } from "react-i18next";

const placeholderVideos = [
  {
    image: "https://cdn-images-1.medium.com/max/720/1*n9yklcoKsmcvo9WFvumdLw.jpeg",
    title: "Video about Milkomeda, how to lorem",
    link: "https://www.youtube.com/watch?v=5cI1DfrlO0E&ab_channel=dcSpark",
  },
  {
    image: "https://cdn-images-1.medium.com/max/720/1*n9yklcoKsmcvo9WFvumdLw.jpeg",
    title: "Video about Milkomeda, how to lorem",
    link: "https://www.youtube.com/watch?v=5cI1DfrlO0E&ab_channel=dcSpark",
  },
  {
    image: "https://cdn-images-1.medium.com/max/720/1*n9yklcoKsmcvo9WFvumdLw.jpeg",
    title: "Video about Milkomeda, how to lorem",
    link: "https://www.youtube.com/watch?v=5cI1DfrlO0E&ab_channel=dcSpark",
  },
  {
    image: "https://cdn-images-1.medium.com/max/720/1*n9yklcoKsmcvo9WFvumdLw.jpeg",
    title: "Video about Milkomeda, how to lorem",
    link: "https://www.youtube.com/watch?v=5cI1DfrlO0E&ab_channel=dcSpark",
  },
];

const UsersWhatIsIt = () => {
  return (
    <section id="what-it-is" className="w-full relative">
      <div className="absolute user-bg inset-0" />
      <div className="section-custom-top pt-64">
        <div className="container relative z-10">
          <div className="flex flex-col-reverse md:flex-row items-center md:space-x-24">
            <div className="flex-1 mt-24 md:mb-0">
              <h3 className="text-28 md:text-40 font-bold mb-16 md:mb-24">
                <Trans>What is Milkomeda?</Trans>
              </h3>
              <p className="text-20 leading-7 text-menu-white">
                <Trans>
                  Milkomeda allows Apps to work in multiple blockchains. Imagine if any cool Android
                  App could work in your iPhone without any changes!
                </Trans>
              </p>
            </div>
            <div className="flex-1">
              <img
                className="aspect-video rounded-[40px]"
                src="/images/main-user-image.jpeg"
                alt=""
              />
            </div>
          </div>
          {/* <div className="flex flex-col md:flex-row space-y-24 md:space-y-0 md:space-x-16">
            {placeholderVideos.map((video, index) => (
              <a className="user-card-link" key={index} href={video.link} target="_blank" rel="noopener noreferrer">
                <div className="relative">
                  <img
                    className="play-icon absolute rounded-full p-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 aspect-ratio"
                    src="/images/icons/play-icon.svg"
                  />
                  <img className="mb-8 rounded-2xl overflow-hidden aspect-ratio bg-gray-400" src={video.image} />
                </div>
                <p className="play-text text-16 font-bold">
                  <Trans>{video.title}</Trans>
                </p>
              </a>
            ))}
          </div> */}
        </div>
      </div>
      <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
        <div className="layout-grid z-10">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </section>
  );
};

export default UsersWhatIsIt;
