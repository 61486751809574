import * as React from "react";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import LinkIcon from "../../static/images/icons/link-icon.inline.svg";
import { addA1Milkomeda, addC1Milkomeda } from "../utils/evmHandlers";

const ContentTab = ({
  id,
  contentMap,
}: {
  id?: string;
  contentMap: { id: number; title: string; content: string }[];
}) => {
  return (
    <Tab.Group
      as="div"
      id={id}
      className={classNames(
        "flex flex-col md:flex-row md:space-x-30",
        // trick to offset div position when using hash fragment
        "-mt-[200px] pt-[200px]"
      )}
    >
      <Tab.List className="pr-0 pt-40 pb-40 basis-[260px] min-w-[260px] md:pr-16 md:pt-80 flex flex-col space-y-24 md:pb-0">
        {contentMap.map((info) => (
          <Tab
            key={info.id}
            className={({ selected }) =>
              classNames(
                "flex items-center space-x-16 transition",
                selected ? "text-white" : "text-btn-text-disabled hover:text-white"
              )
            }
          >
            {({ selected }) => (
              <>
                <div
                  className={classNames(
                    "transition flex items-center justify-center text-22 min-w-[48px] w-48 h-48 rounded-full font-bold",
                    selected ? "bg-orange" : "bg-btn-disabled"
                  )}
                >
                  {info.id}
                </div>
                <p className="text-18 text-left">{info.title}</p>
              </>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="md:shadow-connectContent rounded-40 flex-1 basis-full md:p-40">
        {contentMap.map((info) => (
          <Tab.Panel key={info.id} className="overflow-auto">
            <MDXRenderer>{info.content}</MDXRenderer>
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

const networkHash = {
  cardano: { id: 0, hash: "#cardano-getting-started" },
  algorand: { id: 1, hash: "#algorand-getting-started" },
};

const HowToConnect = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(() => {
    if (typeof window !== "undefined") {
      if (window.location.hash === "") return networkHash.cardano.id;
      return window.location.hash === networkHash.cardano.hash
        ? networkHash.cardano.id
        : window.location.hash === networkHash.algorand.hash
        ? networkHash.algorand.id
        : networkHash.cardano.id;
    }
    return networkHash.cardano.id;
  });

  const data = useStaticQuery<{
    allMdx: {
      nodes: {
        body: string;
        frontmatter: {
          id: number;
          title: string;
          category: "cardano" | "algorand";
        };
      }[];
    };
  }>(graphql`
    query {
      allMdx {
        nodes {
          body
          frontmatter {
            title
            category
            id
          }
        }
      }
    }
  `);
  const { nodes } = data.allMdx;

  const allMarkdownRemark = nodes
    .map((node) => ({
      id: node?.frontmatter?.id,
      title: node?.frontmatter?.title,
      category: node?.frontmatter?.category,
      content: node?.body,
    }))
    .filter((item) => item.id);

  const cardanoContentMap = allMarkdownRemark
    .filter((item) => item?.category === "cardano")
    .sort((a, b) => a.id - b.id);

  const algorandContentMap = allMarkdownRemark
    .filter((item) => item?.category === "algorand")
    .sort((a, b) => a.id - b.id);

  return (
    <section id="how-to-connect" className="relative">
      <div className="section-custom-top pt-64">
        <div className="container relative z-20">
          <div>
            <h2 className="heading-2 mb-40 md:mb-48">
              <Trans>How to connect</Trans>
            </h2>
            <div
              className={classNames(
                "flex flex-col md:flex-row items-center justify-center space-y-24 md:space-y-0 md:space-x-32 mt-10 mb-50",
                // need to overlap after offseting tab content
                "relative z-[2]"
              )}
            >
              <button className="btn-addNetwork" onClick={addC1Milkomeda}>
                <Trans>Add C1 to Metamask</Trans>
              </button>
              <button className="btn-addNetwork" onClick={addA1Milkomeda}>
                <Trans>Add A1 to MetaMask</Trans>
              </button>
            </div>
            <div className="w-full flex flex-col relative pb-40">
              <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <Tab.List className="relative mx-auto md:mx-0 md:absolute md:top-0 md:left-0 w-full max-w-[288px] md:max-w-[270px] flex space-x-1 bg-blue-bg-dark rounded-[160px] h-[40px] p-4 shadow-tabs">
                  <Tab
                    as={Link}
                    to={networkHash.cardano.hash}
                    className={({ selected }) =>
                      classNames(
                        "flex items-center justify-center w-full rounded-[40px] text-16 font-medium leading-5 ",
                        selected ? "bg-orange text-white" : "text-disabled hover:text-white"
                      )
                    }
                  >
                    <Trans>Cardano</Trans>
                  </Tab>
                </Tab.List>
                <Tab.Panels id={networkHash.algorand.hash.split("#").slice(1).join("#")}>
                  {/* Panel: Cardano */}
                  <Tab.Panel className="space-y-28">
                    <ContentTab
                      id={networkHash.cardano.hash.split("#").slice(1).join("#")}
                      contentMap={cardanoContentMap}
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
      <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
        <div className="layout-grid z-10">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </section>
  );
};

export default HowToConnect;
